// Exports:
export default {
  JOB_TITLE: {
    1: 'https://bit.ly/diragb_jobTitle1', // Sunlight
    2: 'https://bit.ly/diragb_jobTitle2', // OpenReply
    3: 'https://bit.ly/diragb_jobTitle3', // Jessy
    4: 'https://bit.ly/diragb_jobTitle4', // Readyly
    5: 'https://bit.ly/diragb_jobTitle5', // GDSC
  },
  LANGUAGE: {
    FIREBASE: 'https://firebase.google.com/',
    PYTHON: 'https://www.python.org',
    REDUX: 'https://redux.js.org/',
    TYPESCRIPT: 'https://www.typescriptlang.org/',
    SOLID: 'https://www.solidjs.com/',
    REACT: 'https://reactjs.org/',
  },
  PROJECT: {
    PREPKARONA: {
      LINK: 'https://prepare-karona.web.app/',
      ME: 'https://www.news18.com/news/buzz/why-is-govt-only-issuing-coronavirus-advisories-in-hindi-and-english-2539391.html',
      DR_VISHAL: 'https://www.youtube.com/watch?v=9kwyvcEJqCs',
      DR_SRINIVAS: 'https://yourstory.com/people/srinivas-dorasala?origin=awards',
      KARNATAKA_STATE_ASSEMBLY: 'https://en.wikipedia.org/wiki/Karnataka_Legislative_Assembly'
    }
  }
}
