// Packages:
import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Color from 'color'


// Typescript:
import { IReduxState } from '../redux/state/types'
import { IProject } from '../constants/projects'


// Constants:
import { COLOR, THEME } from '../constants'


// Components:
import Video from './Video'


// Styles:
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.75em;
  margin-bottom: 2rem;
  
  @media (max-width: 666px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 0.75em;
  }
`

const ImageHolder = styled.img<{ src: string }>`
  width: 61%;
  height: 80%;
  background-image: ${ props => `url(${ props.src })` };
  background-size: cover;
  border-radius: 0.5em;
`

const Image = styled.img`
  border-radius: 0.5em;

  @media (max-width: 480px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 666px) {
    width: 27.5rem;
  }
`

const Details = styled.div<{ rtl: boolean }>`
  /* width: calc(40% - 2em); */
  margin-top: -0.25em;
  padding: 1em;
  padding-top: 0;
  padding-left: ${ props => props.rtl ? '1em' : 0 };
  padding-right: ${ props => props.rtl ? 0 : '1em' };
`

const Title = styled.a`
  width: min-content;
  margin-left: -1px;
  font-weight: 700;
  font-size: 2em;
  color: inherit;
  text-decoration: none;
`

const Metadata = styled.div<{ currentTheme: THEME }>`
  display: flex;
  align-items: center;
  height: 1em;
  margin-top: 0.25em;
  color: ${
    props => props.currentTheme === THEME.DARK ? Color(COLOR.WHITE).desaturate(1).darken(0.5).toString() : Color(COLOR.WHITE).desaturate(1).darken(0.75).toString()
  };

  @media (max-width: 666px) {
    margin-bottom: 0.5rem;
  }
`

const Time = styled.div`
  font-weight: 400;
  font-size: 0.75em;
`

const Dot = styled.div`
  display: inline-block;
  margin: 0 0.5em;
  font-size: 0.75em;
  transform: translateY(-0.1em);
  user-select: none;
`

const Languages = styled.div``

const Language = styled.a`
  text-decoration: none;
`

const LanguageIcon = styled.img<{ hoverColor: string }>`
  width: 0.8em;
  height: 0.8em;
  margin-right: 0.5em;
  border-radius: 3px;
  transition: all 0.25s ease;

  &:hover {
    box-shadow: 0 0 5px 1px ${ props => props.hoverColor };
  }
`

const Description = styled.div`
  margin-top: 0.5em;
  font-weight: 400;
  font-size: 0.9em;
`


// Functions:
const Project = (project: IProject) => {
  // Constants:
  const isomorphicWindow = typeof window !== 'undefined' && window
  const ResizeObserver = (isomorphicWindow as typeof window).ResizeObserver

  // State:
  const theme = useSelector((state: IReduxState) => state.theme)
  const [ videoWidth, setVideoWidth ] = useState('61%')
  const [ isSmall, setIsSmall ] = useState(false)

  // Ref:
  const observer = ResizeObserver ? useRef(
    new ResizeObserver(entries => {
      const { width } = entries[0].contentRect
      if (width < 666) setIsSmall(true)
      else setIsSmall(false)
    })
  ) : useRef()

  // Effects:
  useEffect(() => {
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 1000))
      setVideoWidth('60%')
    })()
  }, [])

  useEffect(() => {
    if (observer.current) observer.current.observe((isomorphicWindow as typeof window).document.body)
    return () => {
      if (observer.current) observer.current.unobserve((isomorphicWindow as typeof window).document.body)
    }
  }, [ observer ])

  // Return:
  return (
    <Wrapper style={ project.style && project.style(isSmall) }>
      {
        isSmall ?
          <>
            <Title href={ project.url } target='_blank'>{ project.title }</Title>
            <Metadata currentTheme={ theme }><Time>{ project.time }</Time> <Dot>•</Dot> <Languages>{ project.languages.map(language => <Language href={ language.link } key={ language.key } target='_blank'><LanguageIcon src={ language.icon } alt={ language.name } aria-label={ language.name } hoverColor={ language.color } /></Language>) }</Languages></Metadata>
            {
              project.src.image ? <Image src={ project.src.image } alt={ project.src.alt } /> : <Video alt={ project.src.alt } isSmall={ true } src={ project.src.video ?? '' } />
            }
            <Description>{ project.description }</Description>
          </>
        :
          <>
            {
              project.rtl && (
                project.src.image ? <ImageHolder aria-label={ project.src.alt } src={ project.src.image } style={ project.src.style } /> : <Video alt={ project.src.alt } isSmall={ false } src={ project.src.video ?? '' } style={{ width: videoWidth }} />
              )
            }
            <Details rtl={ project.rtl ?? false } style={ project.details?.style && project.details?.style(isSmall) }>
              <Title href={ project.url } target='_blank'>{ project.title }</Title>
              <Metadata currentTheme={ theme }><Time>{ project.time }</Time> <Dot>•</Dot> <Languages>{ project.languages.map(language => <Language href={ language.link } key={ language.key } target='_blank'><LanguageIcon src={ language.icon } alt={ language.name } aria-label={ language.name } hoverColor={ language.color } /></Language>) }</Languages></Metadata>
              <Description>{ project.description }</Description>
            </Details>
            {
              !project.rtl && (
                project.src.image ? <ImageHolder aria-label={ project.src.alt } src={ project.src.image } style={ project.src.style } /> : <Video alt={ project.src.alt } isSmall={ false } src={ project.src.video ?? '' } style={{ width: videoWidth }} />
              )
            }
          </>
      }
    </Wrapper>
  )
}


// Exports:
export default Project
