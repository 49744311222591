// Exports:
export const MEDIA = {
  interconnected: 'https://player.vimeo.com/video/674077050?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1&amp;controls=0&amp;loop=1&amp;muted=1&amp;responsive=1'
}

export const LANGUAGE_ICON = {
  FIREBASE: 'https://cdn.iconscout.com/icon/free/png-256/firebase-3521427-2944871.png',
  PYTHON: 'https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/267_Python-512.png',
  REDUX: 'https://cdn.iconscout.com/icon/free/png-256/redux-283024.png',
  TYPESCRIPT: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/512px-Typescript_logo_2020.svg.png',
  SOLID: 'https://www.solidjs.com/img/logo/without-wordmark/logo.png',
  REACT: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png',
}
