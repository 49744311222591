// Packages:
import React, { useState, useRef, useEffect } from 'react'


// Functions:
const getSmallWidth = (width: number) => (0.065 * width) - 3.7

const Video = ({ alt, isSmall, src, style }: { alt: string, isSmall: boolean, src: string, style?: React.CSSProperties }) => {
  // Constants:
  const isomorphicWindow = typeof window !== 'undefined' && window
  const ResizeObserver = isomorphicWindow.ResizeObserver
  const MAX_WIDTH = 27.5
  const HEIGHT_TO_WIDTH_RATIO = 0.44

  // State:
  const [ iframeWidth, setIframeWidth ] = useState(MAX_WIDTH)

  // Ref:
  const observer = ResizeObserver ? useRef(
    new ResizeObserver(entries => {
      const { width } = entries[0].contentRect
      if (width < 480) setIframeWidth(getSmallWidth(width))
    })
  ) : useRef()

  // Effects:
  useEffect(() => {
    if (observer.current) observer.current.observe(isomorphicWindow.document.body)
    return () => {
      if (observer.current) observer.current.unobserve(isomorphicWindow.document.body)
    }
  }, [ observer ])

  // Return:
  return (
  <div aria-label={ alt } style={{ height: isSmall ? 'auto' : '100%', width: isSmall ? '100%' : '61%', ...style }}>
    <iframe
      src={ src }
      frameBorder='0'
      title={ alt }
      allow='autoplay; fullscreen; picture-in-picture'
      style={{
        width: isSmall ? `${ iframeWidth }rem` : '100%',
        height: isSmall ? `${ iframeWidth * HEIGHT_TO_WIDTH_RATIO }rem` : '70.5%',
        borderRadius: isSmall ? '0.5rem' : '0.75rem'
      }}
    />
    <script src='https://player.vimeo.com/api/player.js' />
  </div>
  )
}


// Exports:
export default Video
