// Packages:
import React, { useState } from 'react'
import styled from 'styled-components'
import Color from 'color'
import { useSelector } from 'react-redux'


// Typescript:
import { IReduxState } from '../redux/state/types'


// Constants:
import { THEME, COLOR } from '../constants'
import LINKS from '../constants/links'
import PROJECTS from '../constants/projects'


// Components:
import Project from '../components/Project'


// Styles:
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6em;
`

const Content = styled.div`
  width: 100%;
  max-width: calc(50em - 1.5em);
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 30vh;

  @media (max-width: 666px) {
    width: auto;
    padding: 0 1.5em;
  }
`

const Introduction = styled(Section)``

const Title = styled.div`
  font-weight: 700;
  font-size: 3em;

  @media (max-width: 325px) {
    font-size: 2em;
  }

  @media (min-width: 325px) and (max-width: 400px) {
    font-size: 2.5em;
  }

  @media (min-width: 400px) and (max-width: 666px) {
    font-size: 3em;
  }
`

const NameHighlight = styled.span<{ currentTheme: THEME, isFirefox: boolean }>`
  @property --gradientStartWhite {
    syntax: '<color>';
    initial-value: ${ COLOR.WHITE };
    inherits: false;
  }

  @property --gradientEndWhite {
    syntax: '<color>';
    initial-value: ${ COLOR.WHITE };
    inherits: false;
  }

  @property --gradientStartBlack {
    syntax: '<color>';
    initial-value: ${ COLOR.BLACK };
    inherits: false;
  }

  @property --gradientEndBlack {
    syntax: '<color>';
    initial-value: ${ COLOR.BLACK };
    inherits: false;
  }

  background: ${ props => props.isFirefox ? 'none' :
    `-webkit-linear-gradient(-135deg, var(${ props.currentTheme === THEME.DARK ? '--gradientStartWhite' : '--gradientStartBlack' }), var(${ props.currentTheme === THEME.DARK ? '--gradientEndWhite' : '--gradientEndBlack' }))`
  };
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${ props => props.isFirefox ? (props.currentTheme === THEME.DARK ? COLOR.WHITE : COLOR.BLACK) : 'transparent' };;
  /* user-select: ${ props => props.isFirefox ? 'text' : 'none' }; */
  transition: all 0.25s ease, --gradientStartWhite 0.25s ease, --gradientEndWhite 0.25s ease, --gradientStartBlack 0.25s ease, --gradientEndBlack 0.25s ease;

  &:hover {
    --gradientStartWhite: #ED33B9;
    --gradientEndWhite: #00C2D1;
    --gradientStartBlack: #ED33B9;
    --gradientEndBlack: #00C2D1;
  }

  &::selection {
    -webkit-text-fill-color: ${ props => props.currentTheme === THEME.DARK ? COLOR.WHITE : COLOR.BLACK };
  }
`

const SubTitle = styled.div`
  margin-top: 0.75em;
  font-weight: 400;
  font-size: 1em;

  @media (max-width: 325px) {
    font-size: 0.75em;
  }
`

const Company = styled.a<{ highlightColor: string }>`
  width: 3em;
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.25s ease;

  &:hover {
    color: ${ props => props.highlightColor };
  }
`

const CompanyIcon = styled.img`
  width: 0.75em;
  height: 0.75em;
  user-select: none;
`

const JessyIcon = styled.div`
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  background: linear-gradient(45deg, #BE3E82, #E43F6F, #F56476);
  border-radius: 3px;
`

const Dot = styled.div`
  display: inline-block;
  font-size: 0.75em;
  transform: translateY(-0.1em);
  user-select: none;
`

const Details = styled.div<{ currentTheme: THEME }>`
  margin-top: 0.75em;
  font-weight: 500;
  font-size: 0.75em;
  color: ${
    props => props.currentTheme === THEME.DARK ? Color(COLOR.WHITE).desaturate(1).darken(0.5).toString() : Color(COLOR.WHITE).desaturate(1).darken(0.75).toString()
  };

  @media (max-width: 325px) {
    font-size: 0.6em;
  }

  @media (min-width: 325px) and (max-width: 666px) {
    font-size: 1em;
  }
`

const Projects = styled(Section)`
  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`


// Functions:
const Index = () => {
  // Constants:
  const isomorphicWindow = typeof window !== 'undefined' && window

  // State:
  const [ isFirefox ] = useState([ (isomorphicWindow as typeof window).navigator?.userAgent ?? [] ].includes('Firefox'))
  const theme = useSelector((state: IReduxState) => state.theme)

  // Return:
  return (
    <Wrapper>
      <Content>
        <Introduction>
          <Title>Hey, I'm <NameHighlight currentTheme={ theme } isFirefox={ isFirefox }>Dirag</NameHighlight>.</Title>
          <SubTitle>
            Software Engineer at <Company highlightColor={ '#FD9927' } href={ LINKS.JOB_TITLE[4] } target='_blank'>Readyly</Company> <CompanyIcon src='https://static.wixstatic.com/media/80bee5_ebfa5a84c521486ea859e9be3754c6c0~mv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/80bee5_ebfa5a84c521486ea859e9be3754c6c0~mv2.png' alt='Sunlight Icon' /> <Dot>•</Dot> Lead at <Company highlightColor={ '#4285F4' } href={ LINKS.JOB_TITLE[5] } target='_blank'>GDSC TIIPS</Company> <CompanyIcon src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/24px-Google_%22G%22_Logo.svg.png?20210618182606' alt='GDSC Icon' /> <Dot>•</Dot> Founder at <Company highlightColor={ '#E43F6F' } href={ LINKS.JOB_TITLE[3] } target='_blank'>Jessy</Company> <JessyIcon />
            {/* Founder at <Company highlightColor={ '#0C79BC' } href={ LINKS.JOB_TITLE[2] } target='_blank'>OpenReply</Company> <CompanyIcon src='https://openreply.app/favicon.ico' alt='OpenReply Icon' /> */}
          </SubTitle>
          <Details currentTheme={ theme }>Thinking through things and helping others build a better tomorrow.</Details>
        </Introduction>
        <Projects>
          <Title>Projects</Title>
          {
            PROJECTS.map(project => <Project { ...project } key={ `project-${ project.key }` } />)
          }
        </Projects>
      </Content>
    </Wrapper>
  )
}


// Exports:
export default Index
