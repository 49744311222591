// Packages:
import React from 'react'
import styled from 'styled-components'


// Constants:
import SECONDARY_COLORS, { LANGUAGE_COLOR } from './colors'
import LINKS from '../constants/links'
import { LANGUAGE_ICON } from '../constants/assetLinks'


// Functions:
const generateRandomColorIndex = () => Math.floor(Math.random() * SECONDARY_COLORS.FLASHY_COLORS.length)


// Exports:
export const Highlight = styled.span`
  font-weight: 600;
  transition: color 0.25s ease;

  &:hover {
    color: ${ props => SECONDARY_COLORS.FLASHY_COLORS[ generateRandomColorIndex() ] };
  }
`

export const LinkHighlight = styled.a`
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  transition: color 0.25s ease;

  &:hover {
    color: ${ props => SECONDARY_COLORS.FLASHY_COLORS[ generateRandomColorIndex() ] };
  }
`

export const Language = styled.a<{ color: string }>`
  color: ${ props => props.color };
  font-weight: 600;
  text-decoration: none;
`

export const General = styled.a<{ color: string }>`
  display: inline-block;
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.25s ease;

  &:hover {
    color: ${ props => props.color };
  }
`

export const LanguageIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.2rem;
  user-select: none;
`

export const GeneralIcon = styled.img`
  height: 0.75rem;
  margin-right: 0.35rem;
  user-select: none;
`

export const LANGUAGE_JSX = {
  PYTHON: <Language color={ LANGUAGE_COLOR.PYTHON } href={ LINKS.LANGUAGE.PYTHON } target='_blank'><LanguageIcon src={ LANGUAGE_ICON.PYTHON } alt={ 'Python' } />Python</Language>,
  TYPESCRIPT: <Language color={ LANGUAGE_COLOR.TYPESCRIPT } href={ LINKS.LANGUAGE.TYPESCRIPT } target='_blank'><LanguageIcon src={ LANGUAGE_ICON.TYPESCRIPT } alt={ 'Typescript' } />Typescript</Language>,
  SOLID: <Language color={ LANGUAGE_COLOR.SOLID } href={ LINKS.LANGUAGE.SOLID } target='_blank'><LanguageIcon src={ LANGUAGE_ICON.SOLID } alt={ 'Solid' } />Solid</Language>,
  REACT: <Language color={ LANGUAGE_COLOR.REACT } href={ LINKS.LANGUAGE.REACT } target='_blank'><LanguageIcon src={ LANGUAGE_ICON.REACT } alt={ 'React' } />React</Language>
}

export const GENERAL_JSX = {
  TWITTER: <General color={ '#1DA1F2' } href={ 'https://twitter.com/diragb' } target='_blank'><GeneralIcon src={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png' } alt={ 'Twitter' } />@diragb</General>,
  EMAIL: <General color={ '#4285F4' } href={ 'mailto:work@diragb.dev' } target='_blank'><GeneralIcon src={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png' } alt={ 'work@diragb.dev' } />work@diragb.dev</General>,
  GITHUB: <General color={ '#6E5494' } href={ 'https://github.com/diragb' } target='_blank'><GeneralIcon src={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Github-desktop-logo-symbol.svg/1024px-Github-desktop-logo-symbol.svg.png' } alt={ 'GitHub' } />@diragb</General>,
  LINKEDIN: <General color={ '#0077B5' } href={ 'https://www.linkedin.com/in/diragb' } target='_blank'><GeneralIcon src={ 'https://cdn-icons-png.flaticon.com/512/174/174857.png' } alt={ 'LinkedIn' } />Dirag Biswas</General>,
}
